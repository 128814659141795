<template>
  <div class="agreement">
    <h3 class="agree-title">学信网授权协议</h3>

    <p class="agree-text" style="font-weight:600;text-decoration: underline;">
      如您点击【同意本协议】即视为您获得有效授权且可将该等授权转授权本网站，您确认获得有效的转授权我们查看您拟查询的第三人的学信网（学历学籍）信息，包括但不限于第三人身份信息、其所在院校信息、专业信息、入学时间、毕业时间、学籍状态、证书编号等在内的各类信息，并将视为已阅读并理解本协议的全部内容。
    </p>

    <p class="agree-text  imt-1" style="font-weight:600;text-decoration: underline;">
      请您仔细阅读粗体字的标注，并确保被查询的第三人已明确知悉本协议的某些条款可能对其的权益具有或可能具有重大关系的条款，以及对本公司具有或可能具有免责或限制责任的条款。 如果您不同意本协议的任意内容，或者无法准确理解本网站对条款的解释，或者未获得第三人的有效授权请不要进行后续操作。一旦您点击同意本协议或进行后续操作，或使用本网站的服务，均视为您同意本协议。
    </p>

    <p class="agree-text  imt-1">
      一、授权条款
    </p>

    <p class="agree-text">
      (1)您确认，在您转授权我们验证并获取第三人的手机运营商信息之前，您已充分阅读、理解并接受本协议的全部内容，一旦您使用本服务，即表示您同意并遵循本协议之所有约定。
    </p>

    <p class="agree-text">
      (2)您同意，在您转授权我们验证并获取第三人的手机运营商信息后，<span style="text-decoration: underline;font-weight: 600;">我们有权查看并读取包括但不限于第三人的身份信息、所在院校、专业信息、入学时间、毕业时间、学籍状态、证书编号等信息。</span>
    </p>

    <p class="agree-text imt-1">
      二、保密条款本网站重视对用户隐私的保护，因收集第三人的信息是出于遵守国家法律法规的规定以及向您提供服务，本网站不会因商业目的而向他人提供您/第三人的任何信息，我们会在下列情况下才将手机运营商信息与第三方共享：
    </p>
    <p class="agree-text">
      (1)获得信息主体的同意/授权/转授权；
    </p>
    <p class="agree-text">
      (2)为了向您提供或推荐服务、产品、或为了向您提供更完善的服务，我们会在必要时向合作机构共享您的相关信息；
    </p>
    <p class="agree-text">
      (3)在某些情况下只有共享您的信息才能向您提供您需要的服务或者产品，或处理您与他人的交易或者纠纷、争议；
    </p>
    <p class="agree-text">
      (4)为了判断您的账户是否安全；
    </p>
    <p class="agree-text">
      (5)为维护本网站及关联公司、第三方的合法权益；
    </p>
    <p class="agree-text">
      (6)如您授权第三方向本网站查询、采集您账户相关信息，我们有权在法律法规和您的授权许可范围内向第三方分享您账户的部分信息。
    </p>
    <p class="agree-text">
      (7)根据法律法规的规定或有关机关的要求。
    </p>
    <p class="agree-text imt-1" style="font-weight:600;text-decoration: underline;">
      三、用户义务及免责声明 您承诺，您所提供的第三人信息均为第三人有效授权您享有、获取、保存的第三人的真实信息，不得为虚假或非法获取的信息；若涉嫌虚假信息或盗用他人信息，本网站有权拒绝为您提供服务，由此而产生的全部法律责任将由您自行承担，本网站不对此承担任何法律责任，若因此对本网站带来损失的，本网站有权追究您的法律责任。
    </p>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
@import './styles.scss';

.agreement {
  padding-right: 7px;
  max-height: 55vh;
  overflow: auto;
  @include scroll-bar-grey;
}
.agree-title {
  margin-bottom: 2em;
  text-align: center;
}
.agree-list {
  list-style-position: inside;
  list-style-type: disc;
}
</style>
